import React, { useState } from 'react';
import Img from 'gatsby-image';
import Modal from 'react-modal';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { Link } from '../../components/common/Misc';
import Header from '../../components/common/Header';
import SubHeader from '../../components/common/SubHeader';

import styles, { cardStyles, sliderStyles } from './eventoStyles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';

const settings = {
    arrows: false,
    dots: true,
    speed: 1000,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 3000,
                dots: true,
                draggable: false,
            }
        },
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                dots: false,
                draggable: true,
            }
        },
        {
            breakpoint: 651,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 500,
                dots: false,
                draggable: true,
            }
        }
    ]
};

const EventoTemplate = (props) => {
    const { data: { strapiGrupoEventos: grEvents } } = props;
    const [state, setState] = useState({
        gallery: null,
        modalIsOpen: false
    });
    const onOpenModal = (gallery) => {
        setState((prevState) => ({
            ...prevState,
            gallery,
            modalIsOpen: true
        }));
    };
    const onCloseModal = () => {
        setState((prevState) => ({
            ...prevState,
            gallery: null,
            modalIsOpen: false
        }));
    };
    const eventImages = () => {
        if (state.gallery && state.gallery.length > 0) {
            return state.gallery.map((mf, i) => (
                <img
                    className="event-slider-image"
                    src={mf.localFile.childImageSharp.fluid.src}
                    alt={mf.name}
                    key={`mf-${mf.id}-${i}`}
                />
            ));
        }
        return <div />;
    };
    return (
        <main css={styles}>
            <Header section="events" />
            <SubHeader title={grEvents.name} />
            <div className="back-to">
                <Link
                    to="/eventos"
                    rel="nofollow noopener noreferrer"
                >
                    &larr; Eventos
                </Link>
            </div>
            {(() => {
                if (grEvents.groupImage) {
                    return (
                        <Img
                            fluid={grEvents.groupImage.localFile.childImageSharp.fluid}
                            alt={grEvents.eventName}
                            className="events-image"
                        />
                    );
                }
                return null;
            })()}
            {(() => {
                if (grEvents.description) {
                    return (
                        <p className="events-description">
                            {grEvents.description}
                        </p>
                    );
                }
                return null;
            })()}
            <div className="events-grid">
                {grEvents.events.sort((a,b) => moment(a.published_at) - moment(b.published_at)).map((event) => {
                    const hasImage = event.eventImage !== null;
                    return (
                        <div className="event-card" key={event.id} css={cardStyles(hasImage)}>
                            {(() => {
                                if (hasImage) {
                                    return (
                                        <Img
                                            fluid={event.eventImage.localFile.childImageSharp.fluid}
                                            alt={event.eventName}
                                            className="event-card-image"
                                        />
                                    );
                                }
                                return null;
                            })()}
                            <div className="event-card-info">
                                <h4>{event.eventName}</h4>
                                <p>{event.description}</p>
                                <p>{event.location}</p>
                                {(() => {
                                    if (event.gallery && event.gallery.length > 0) {
                                        return (
                                            <button type="button" onClick={() => onOpenModal(event.gallery)}>
                                                Ver imágenes
                                            </button>
                                        );
                                    }
                                    return null;
                                })()}
                            </div>
                        </div>
                    );
                })}
            </div>
            <Modal
                isOpen={state.modalIsOpen}
                onRequestClose={onCloseModal}
                style={{
                    content: {
                        width: '90%',
                        maxWidth: 900,
                        height: 400,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }
                }}
            >
                <div css={sliderStyles}>
                    <Slider {...settings}>
                        {eventImages()}
                    </Slider>
                </div>
            </Modal>
        </main>
    );
};

EventoTemplate.propTypes = {
    data: PropTypes.object.isRequired
};

export const pageQuery = graphql`
    query EventoByPath($slug: String) {
        strapiGrupoEventos(slug: { eq: $slug }) {
            id
            name
            description
            groupImage {
              localFile {
                childImageSharp {
                  fluid {
                    srcSetWebp
                    srcSet
                    sizes
                    src
                  }
                }
              }
            }
            events {
              id
              eventName
              published_at
              eventImage {
                localFile {
                  childImageSharp {
                    fluid {
                      srcSetWebp
                        srcSet
                        sizes
                        src
                    }
                  }
                }
              }
              description
              location
              gallery {
                id
                name
                url
                localFile {
                  childImageSharp {
                    fluid {
                      srcSetWebp
                        srcSet
                        sizes
                        src
                    }
                  }
                }
              }
            }
        }
    }
`;

export default EventoTemplate;
