import { css } from '@emotion/core';

export default css({
    overflow: 'auto',
    height: '100vh',
    background: 'rgb(249, 249, 249)',
    '& .back-to': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '35px auto',
        padding: '0px 10px',
        maxWidth: 1000,
        '& > a': {
            color: '#666',
            textDecoration: 'none'
        }
    },
    '& .events-image': {
        maxWidth: 300,
        margin: '-15px auto 50px'
    },
    '& .events-description': {
        fontSize: 22,
        lineHeight: '30px',
        color: '#1f300d',
        textAlign: 'justify',
        margin: '0px auto 50px',
        padding: '0px 30px',
        maxWidth: 1366
    },
    '& .events-grid': {
        maxWidth: '1000px',
        margin: '0px auto 50px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridGap: '1em',
    },
    '@media only screen and (min-width: 768px)': {
        '& .events-description': {
            padding: '0px 70px',
        }
    }
});

export const cardStyles = (hasImage) => css({
    width: 300,
    height: '100%',
    margin: 15,
    background: '#FFF',
    boxSizing: 'border-box',
    boxShadow: '0 1px 1px rgba(0,0,0,0.12),0 0px 1px rgba(0,0,0,0.24)',
    justifySelf: 'center',
    '& .event-card': {
        '&-image': {
            width: '100%',
            height: 290,
            marginBottom: 15,
            '& > picture > img': {
                objectFit: 'contain!important'
            }
        },
        '&-info': {
            height: hasImage ? 'calc(100% - 320px)' : '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        },
    },
    '& h4': {
        fontSize: 20,
        color: '#1f300d',
        textAlign: 'center',
        textTransform: 'uppercase',
        margin: '30px 0px',
        padding: '0px 20px'
    },
    '& p': {
        fontSize: 16,
        fontWeight: 300,
        textAlign: 'center',
        margin: '15px 0px',
        padding: '0px 20px',
        lineHeight: '22px'
    },
    '& button': {
        display: 'block',
        fontSize: 16,
        color: '#FFF',
        textAlign: 'center',
        background: '#f5333f',
        margin: '30px auto 10px',
        textTransform: 'uppercase',
        border: 'none',
        borderRadius: '10px',
        padding: '15px 40px'
    }
});

export const sliderStyles = {
    '& .slick-slider': {
        maxWidth: '1366px',
        margin: '0px auto',
        background: 'transparent',
    },
    '& .event-slider-image': {
        width: '300px!important',
        borderRadius: 10,
        display: 'block!important',
        margin: '0px auto'
    },
    '@media only screen and (min-width: 768px)': {
        '& .event-slider-image': {
            width: '500px!important',
        }
    }
};
